import { api } from '@/api';
import { ActionContext } from 'vuex';
import {
  IApp,
  IAppCreate,
  ICountryClusterCreate,
  ICountryLibrary,
  IUser,
  IUserCreate,
} from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetUsers,
  commitSetUser,
  commitSetApps,
  commitSetApp,
  commitSetSelectedUsers,
  commitSetSelectedApps,
  commitSetCountryClusters,
  commitRemoveCountryCluster,
  commitSetMyCountries,
  commitSetSelectedCountries,
  commitSetUserError,
  commitSetUserPasswordError,
  commitClearUserResult,
  commitClearAppResult,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '../main/mutations';
import { AxiosError } from 'axios';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserCreate) {
    const notification = { content: 'Saving new user', showProgress: true };
    try {
      commitAddNotification(context, notification);
      commitClearUserResult(context);
      const response = await api.createUser(
        context.rootState.main.token,
        payload,
      );
      commitRemoveNotification(context, notification);
      if (response.data.result) {
        commitSetUser(context, response.data.result);
        commitAddNotification(context, {
          content: 'User successfully created',
          color: 'success',
        });
      } else if (response.data.error) {
        commitSetUserError(context, response.data.error);
      } else if (response.data.password_error) {
        commitSetUserPasswordError(context, response.data.password_error);
      }
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating user',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserCreate },
  ) {
    const notification = { content: 'Saving user', showProgress: true };
    try {
      commitAddNotification(context, notification);
      commitClearUserResult(context);
      const response = await api.updateUser(
        context.rootState.main.token,
        payload.id,
        payload.user,
      );
      commitRemoveNotification(context, notification);
      if (response.data.result) {
        commitSetUser(context, response.data.result);
        commitAddNotification(context, {
          content: 'User successfully updated',
          color: 'success',
        });
      } else if (response.data.error) {
        commitSetUserError(context, response.data.error);
      } else if (response.data.password_error) {
        commitSetUserPasswordError(context, response.data.password_error);
      }
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating user',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetApps(context: MainContext) {
    try {
      const response = await api.getApps(context.rootState.main.token);
      if (response) {
        commitSetApps(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateApp(context: MainContext, payload: IAppCreate) {
    const notification = {
      content: 'Saving new application',
      showProgress: true,
    };
    try {
      commitAddNotification(context, notification);
      commitClearAppResult(context);
      const response = await api.createApp(
        context.rootState.main.token,
        payload,
      );
      commitSetApp(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Application successfully created',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating application',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateApp(
    context: MainContext,
    payload: { id: number; app: IAppCreate },
  ) {
    const notification = { content: 'Saving application', showProgress: true };
    try {
      commitAddNotification(context, notification);
      commitClearAppResult(context);
      const response = await api.updateApp(
        context.rootState.main.token,
        payload.id,
        payload.app,
      );
      commitSetApp(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Application successfully updated',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating application',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetSelectedUsers(
    context: MainContext,
    payload: { appId: number },
  ) {
    try {
      const response = await api.getUsersByApp(
        context.rootState.main.token,
        payload.appId,
      );
      if (response) {
        commitSetSelectedUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetSelectedApps(context: MainContext, payload: number) {
    try {
      const response = await api.getAppsByUser(
        context.rootState.main.token,
        payload,
      );
      if (response) {
        commitSetSelectedApps(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionAssignUsers(
    context: MainContext,
    payload: { appId: number; users: IUser[] },
  ) {
    try {
      await api.assignUsers(
        context.rootState.main.token,
        payload.appId,
        payload.users,
      );
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionAssignApps(
    context: MainContext,
    payload: { userId: number; apps: IApp[] },
  ) {
    try {
      await api.assignApps(
        context.rootState.main.token,
        payload.userId,
        payload.apps,
      );
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetCountryClusters(context: MainContext) {
    try {
      const response = await api.getCountryClusters(
        context.rootState.main.token,
      );
      if (response) {
        commitSetCountryClusters(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateCountryCluster(
    context: MainContext,
    payload: ICountryClusterCreate,
  ) {
    const notification = {
      content: 'Saving new Country Cluster',
      showProgress: true,
    };
    try {
      commitAddNotification(context, notification);
      commitClearAppResult(context);
      const response = await api.createCountryCluster(
        context.rootState.main.token,
        payload,
      );
      commitSetApp(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'CountryCluster successfully created',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating Country Cluster',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateCountryCluster(
    context: MainContext,
    payload: { id: number; countryCluster: ICountryClusterCreate },
  ) {
    const notification = {
      content: 'Saving Country Cluster',
      showProgress: true,
    };
    try {
      commitAddNotification(context, notification);
      commitClearAppResult(context);
      const response = await api.updateCountryCluster(
        context.rootState.main.token,
        payload.id,
        payload.countryCluster,
      );
      commitSetApp(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Country Cluster successfully updated',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating Country Cluster',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionRemoveCountryCluster(
    context: MainContext,
    countryClusterId: number,
  ) {
    const notification = {
      content: 'Removing Country Cluster',
      showProgress: true,
    };
    try {
      commitAddNotification(context, notification);
      const response = await api.removeCountryCluster(
        context.rootState.main.token,
        countryClusterId,
      );
      commitRemoveCountryCluster(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Country Cluster successfully removed',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error removing Country Cluster',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetMyCountries(context: MainContext) {
    try {
      const response = await api.getMyCountries(context.rootState.main.token);
      if (response) {
        commitSetMyCountries(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetSelectedCountries(context: MainContext, payload: number) {
    try {
      const response = await api.getCountriesByUser(
        context.rootState.main.token,
        payload,
      );
      if (response) {
        commitSetSelectedCountries(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionAssignCountries(context: MainContext, payload: ICountryLibrary) {
    try {
      await api.assignCountries(context.rootState.main.token, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchGetApps = dispatch(actions.actionGetApps);
export const dispatchCreateApp = dispatch(actions.actionCreateApp);
export const dispatchUpdateApp = dispatch(actions.actionUpdateApp);
export const dispatchGetSelectedUsers = dispatch(
  actions.actionGetSelectedUsers,
);
export const dispatchGetSelectedApps = dispatch(actions.actionGetSelectedApps);
export const dispatchAssignUsers = dispatch(actions.actionAssignUsers);
export const dispatchAssignApps = dispatch(actions.actionAssignApps);
export const dispatchGetCountryClusters = dispatch(
  actions.actionGetCountryClusters,
);
export const dispatchCreateCountryCluster = dispatch(
  actions.actionCreateCountryCluster,
);
export const dispatchUpdateCountryCluster = dispatch(
  actions.actionUpdateCountryCluster,
);
export const dispatchRemoveCountryCluster = dispatch(
  actions.actionRemoveCountryCluster,
);
export const dispatchGetMyCountries = dispatch(actions.actionGetMyCountries);
export const dispatchGetSelectedCountries = dispatch(
  actions.actionGetSelectedCountries,
);
export const dispatchAssignCountries = dispatch(actions.actionAssignCountries);
