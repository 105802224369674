import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ConsumersState } from './state';

const defaultState: ConsumersState = {
  consumers: [],
  consumerResult: 0,
  consumerError: null,
  consumerPasswordError: null,
  stats: [],
};

export const consumersModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
