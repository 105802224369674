export enum LoginError {
  USER_NOT_FOUND = 1,
  INCORRECT_CREDENTIALS,
  USER_INACTIVE,
}

export enum SsoError {
  PROVIDER = 1,
  USER_NOT_FOUND,
  USER_INACTIVE,
  USER_NOT_SSO,
}

export enum UserError {
  ALREADY_EXISTS = 1,
  DOES_NOT_EXIST,
}

export enum ConsumerError {
  ALREADY_EXISTS = 1,
  DOES_NOT_EXIST,
}

export enum PasswordError {
  MIN_LENGTH = 1,
  LOW_COMPLEXITY,
  REUSED_LAST_PASSWORDS,
  CONTAINS_USERNAME_OR_EMAIL,
  CONTAINS_BANNED_WORDS,
  NOT_UNIQUE,
}

export interface LoginOut {
  result?: Token;
  error?: LoginError;
}

export interface SsoOut {
  result?: Token;
  error?: SsoError;
}

export interface Token {
  access_token: string;
  token_type: string;
}

export interface IProfileOut {
  result?: IProfile;
  error?: UserError;
  password_error?: PasswordError;
}

export interface IProfile {
  id: number;
  full_name: string;
  email: string;
  role: number;
  sso: number;
}

export interface IProfileUpdate {
  full_name: string;
  email: string;
  password: string;
}

export interface IUserOut {
  result?: IUser;
  error?: UserError;
  password_error?: PasswordError;
}

export interface IUser {
  id: number;
  full_name: string;
  email: string;
  is_active: boolean;
  login_date: string;
  role: number;
  sso: number;
}

export interface IUserCreate {
  full_name: string;
  email: string;
  password: string;
  is_active: boolean;
  role: number;
  sso: number;
}

export interface IConsumerOut {
  result?: IConsumer;
  error?: ConsumerError;
  password_error?: PasswordError;
}

export interface IConsumer {
  id: number;
  name: string;
  email: string;
  registration_date: string;
  login_date: string;
  is_active: boolean;
  sso: number;
}

export interface IConsumerCreate {
  name: string;
  email: string;
  password: string;
  is_active: boolean;
  sso: number;
}

export interface IFilter {
  id: number;
  name: string;
  filters: IFilterItem[];
}

export interface IFilterCreate {
  name: string;
  filters: IFilterItem[];
}

export interface IFilterItem {
  type: string;
  name: string;
  value: any;
}

export interface IVersion {
  app_version: string;
}

export interface IScan {
  id: number;
  timestamp: string;
  consumer: string;
  is_aacs: boolean;
  product: string;
  image: string;
  bottle_size: string;
  information: string;
  note: string;
  result: number;
  is_test_sample: boolean;
  is_wrong_sample: boolean;
  is_incorrect_details: boolean;
  comment: string;
  address: string;
  country_abbr: string;
  geo_lat: number;
  geo_lng: number;
  vendor_name: string;
  vendor_street: string;
  vendor_city: string;
  vendor_country: string;
  phone_model: string;
  os_version: string;
  app_version: string;
}

export interface IScanStats {
  main: IBarStatsItem[];
  brands: IPieStatsItem[];
}

export interface IBarStatsItem {
  month: string;
  authentic: number;
  suspect: number;
}

export interface IPieStatsItem {
  product_id: number;
  authentic: number;
  suspect: number;
}

export interface IConsumerStats {
  month: string;
  registration: number;
}

export interface IStatsData {
  bar: string;
  pies: string[];
  filters: IFilterItem[];
}

export interface ITarget {
  id: number;
  app_id: number;
  product_id: number;
  model: string;
  code: string;
}

export interface IProduct {
  id: number;
  name: string;
  image: string;
  description: string;
}

export interface IApp {
  id: number;
  abbr: string;
  name: string;
  description: string;
  consumer: number;
  sso: number;
}

export interface IAppCreate {
  abbr: string;
  name: string;
  description?: string;
  consumer: number;
  sso: number;
}

export interface ICountryCluster {
  id: number;
  name: string;
  countries: string;
  is_active: boolean;
}

export interface ICountryClusterCreate {
  name: string;
  countries: string;
  is_active: boolean;
}

export interface ICountryLibrary {
  user_id: number;
  countries: string;
}

export interface IMapCluster {
  geo_lat: number;
  geo_lng: number;
  authentic_count: number;
  suspect_count: number;
  accuracy: number;
}

export interface IMapData {
  image: string;
  filters: IFilterItem[];
  sort: string;
  desc: boolean;
  bounds: string;
  geo_lat: number;
  geo_lng: number;
  accuracy: number;
}
