import { IMapCluster, IScan } from '@/interfaces';
import { MapsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setMapClusters(state: MapsState, payload: IMapCluster[]) {
    state.clusters = payload;
  },
  clearMapClusters(state: MapsState) {
    state.clusters = [];
  },
  setMapScans(state: MapsState, payload: { data: IScan[]; append: boolean }) {
    state.scans = payload.append
      ? state.scans.concat(payload.data)
      : payload.data;
  },
  clearMapScans(state: MapsState) {
    state.scans = [];
  },
};

const { commit } = getStoreAccessors<MapsState, State>('');

export const commitSetMapClusters = commit(mutations.setMapClusters);
export const commitClearMapClusters = commit(mutations.clearMapClusters);
export const commitSetMapScans = commit(mutations.setMapScans);
export const commitClearMapScans = commit(mutations.clearMapScans);
