import {
  ConsumerError,
  IConsumer,
  IConsumerStats,
  PasswordError,
} from '@/interfaces';
import { ConsumersState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setConsumers(
    state: ConsumersState,
    payload: { data: IConsumer[]; append: boolean },
  ) {
    state.consumers = payload.append
      ? state.consumers.concat(payload.data)
      : payload.data;
  },
  setConsumer(state: ConsumersState, payload: IConsumer) {
    const consumers = state.consumers.filter(
      (consumer: IConsumer) => consumer.id !== payload.id,
    );
    consumers.push(payload);
    state.consumers = consumers;
    state.consumerResult = payload.id;
  },
  clearConsumerResult(state: ConsumersState) {
    state.consumerResult = 0;
  },
  setConsumerError(state: ConsumersState, payload: ConsumerError) {
    state.consumerError = payload;
  },
  clearConsumerError(state: ConsumersState) {
    state.consumerError = null;
  },
  setConsumerPasswordError(state: ConsumersState, payload: PasswordError) {
    state.consumerPasswordError = payload;
  },
  clearConsumerPasswordError(state: ConsumersState) {
    state.consumerPasswordError = null;
  },
  setConsumerStats(state: ConsumersState, payload: { data: IConsumerStats[] }) {
    state.stats = payload.data;
  },
};

const { commit } = getStoreAccessors<ConsumersState, State>('');

export const commitSetConsumers = commit(mutations.setConsumers);
export const commitSetConsumer = commit(mutations.setConsumer);
export const commitClearConsumerResult = commit(mutations.clearConsumerResult);
export const commitSetConsumerError = commit(mutations.setConsumerError);
export const commitClearConsumerError = commit(mutations.clearConsumerError);
export const commitSetConsumerPasswordError = commit(
  mutations.setConsumerPasswordError,
);
export const commitClearConsumerPasswordError = commit(
  mutations.clearConsumerPasswordError,
);
export const commitSetConsumerStats = commit(mutations.setConsumerStats);
