import { IProfile, PasswordError, UserError } from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLoginError(state: MainState, payload: boolean) {
    state.loginError = payload;
  },
  setProfile(state: MainState, payload: IProfile) {
    state.profile = payload;
  },
  setProfileError(state: MainState, payload: UserError) {
    state.profileError = payload;
  },
  clearProfileError(state: MainState) {
    state.profileError = null;
  },
  setProfilePasswordError(state: MainState, payload: PasswordError) {
    state.profilePasswordError = payload;
  },
  clearProfilePasswordError(state: MainState) {
    state.profilePasswordError = null;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload,
    );
  },
};

const { commit } = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardShowDrawer = commit(
  mutations.setDashboardShowDrawer,
);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLoginError = commit(mutations.setLoginError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetProfile = commit(mutations.setProfile);
export const commitSetProfileError = commit(mutations.setProfileError);
export const commitClearProfileError = commit(mutations.clearProfileError);
export const commitSetProfilePasswordError = commit(
  mutations.setProfilePasswordError,
);
export const commitClearProfilePasswordError = commit(
  mutations.clearProfilePasswordError,
);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
