import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { MapsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitClearMapClusters,
  commitClearMapScans,
  commitSetMapClusters,
  commitSetMapScans,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IFilterItem } from '@/interfaces';
import { AxiosError } from 'axios';

type MainContext = ActionContext<MapsState, State>;

export const actions = {
  async actionGetMapClusters(
    context: MainContext,
    payload: {
      appId: number;
      filters: IFilterItem[];
      bounds: string;
      zoom: number;
    },
  ) {
    try {
      const response = await api.getMapClusters(
        context.rootState.main.token,
        payload.appId,
        payload.filters,
        payload.bounds,
        payload.zoom,
      );
      if (response) {
        commitSetMapClusters(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  actionClearMapClusters(context: MainContext) {
    commitClearMapClusters(context);
  },
  async actionGetMapScans(
    context: MainContext,
    payload: {
      appId: number;
      filters: IFilterItem[];
      skip: number;
      limit: number;
      sort: string;
      desc: boolean;
      bounds: string;
      geo_lat: number;
      geo_lng: number;
      accuracy: number;
    },
  ) {
    try {
      const response = await api.getMapScans(
        context.rootState.main.token,
        payload.appId,
        payload.filters,
        payload.skip,
        payload.limit,
        payload.sort,
        payload.desc,
        payload.bounds,
        payload.geo_lat,
        payload.geo_lng,
        payload.accuracy,
      );
      if (response) {
        commitSetMapScans(context, {
          data: response.data,
          append: payload.skip !== 0,
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  actionClearMapScans(context: MainContext) {
    commitClearMapScans(context);
  },
};

const { dispatch } = getStoreAccessors<MapsState, State>('');

export const dispatchGetMapClusters = dispatch(actions.actionGetMapClusters);
export const dispatchClearMapClusters = dispatch(
  actions.actionClearMapClusters,
);
export const dispatchGetMapScans = dispatch(actions.actionGetMapScans);
export const dispatchClearMapScans = dispatch(actions.actionClearMapScans);
