import { api } from '@/api';
import { IProfileUpdate, LoginError, SsoError } from '@/interfaces';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLoginError,
  commitSetToken,
  commitSetProfile,
  commitSetProfileError,
  commitSetProfilePasswordError,
} from './mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogin(
    context: MainContext,
    payload: { username: string; password: string },
  ) {
    try {
      const response = await api.login(payload.username, payload.password);
      if (response.data.result) {
        dispatchLoggedIn(context, response.data.result.access_token);
      } else {
        var content = '';
        switch (response.data.error) {
          case LoginError.USER_NOT_FOUND:
            content = 'User not found';
            break;
          case LoginError.INCORRECT_CREDENTIALS:
            content = 'Incorrect email or password';
            break;
          case LoginError.USER_INACTIVE:
            content = 'Inactive user';
            break;
        }
        commitAddNotification(context, {
          content: content,
          color: 'error',
        });
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLoginError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionLoginSso(context: MainContext, payload: string) {
    try {
      const response = await api.loginSso(payload);
      if (response.data.result) {
        dispatchLoggedIn(context, response.data.result.access_token);
      } else {
        var content = '';
        switch (response.data.error) {
          case SsoError.PROVIDER:
            content = 'Authentication error';
            break;
          case SsoError.USER_NOT_FOUND:
            content = 'User not found';
            break;
          case SsoError.USER_INACTIVE:
            content = 'Inactive user';
            break;
          case SsoError.USER_NOT_SSO:
            content = 'SSO authorization is not available to the user';
            break;
        }
        commitAddNotification(context, {
          content: content,
          color: 'error',
        });
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLoginError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionLoggedIn(context: MainContext, payload: string) {
    saveLocalToken(payload);
    commitSetToken(context, payload);
    commitSetLoggedIn(context, true);
    commitSetLoginError(context, false);
    await dispatchGetProfile(context);
    await dispatchRouteLoggedIn(context);
    commitAddNotification(context, {
      content: 'Logged in',
      color: 'success',
    });
  },
  async actionGetProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionUpdateProfile(context: MainContext, payload: IProfileUpdate) {
    const notification = { content: 'Saving profile', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.updateMe(context.state.token, payload);
      commitRemoveNotification(context, notification);
      if (response.data.result) {
        commitSetProfile(context, response.data.result);
        commitAddNotification(context, {
          content: 'Profile successfully updated',
          color: 'success',
        });
      } else if (response.data.error) {
        commitSetProfileError(context, response.data.error);
      } else if (response.data.password_error) {
        commitSetProfilePasswordError(context, response.data.password_error);
      }
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error updating profile',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await api.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetProfile(context, response.data);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, '');
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: 'Logged out', color: 'success' });
  },
  actionRouteLogOut(context: MainContext) {
    if (router.currentRoute.path !== '/login') {
      router.go(0);
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload.response!.status === 403) {
      await dispatchLogOut(context);
    }
  },
  actionRouteLoggedIn(context: MainContext) {
    if (
      router.currentRoute.path === '/login' ||
      router.currentRoute.path === '/redirect' ||
      router.currentRoute.path === '/login-admin' ||
      router.currentRoute.path === '/'
    ) {
      router.push('/main');
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number },
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: 'Sending password recovery email',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.passwordRecovery(payload.username),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Password recovery email sent',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Incorrect username',
      });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    const loadingNotification = {
      content: 'Resetting password',
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.resetPassword(payload.password, payload.token),
          await new Promise<void>((resolve, reject) =>
            setTimeout(() => resolve(), 500),
          ),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: 'Password successfully reset',
        color: 'success',
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: 'error',
        content: 'Error resetting password',
      });
    }
  },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetProfile = dispatch(actions.actionGetProfile);
export const dispatchLogin = dispatch(actions.actionLogin);
export const dispatchLoginSso = dispatch(actions.actionLoginSso);
export const dispatchLoggedIn = dispatch(actions.actionLoggedIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateProfile = dispatch(actions.actionUpdateProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
