import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  users: (state: AdminState) => state.users,
  userResult: (state: AdminState) => state.userResult,
  userError: (state: AdminState) => state.userError,
  userPasswordError: (state: AdminState) => state.userPasswordError,
  user: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  apps: (state: AdminState) => state.apps,
  appResult: (state: AdminState) => state.appResult,
  app: (state: AdminState) => (appId: number) => {
    const filteredApps = state.apps.filter((app) => app.id === appId);
    if (filteredApps.length > 0) {
      return { ...filteredApps[0] };
    }
  },
  selectedUsers: (state: AdminState) => state.selectedUsers,
  selectedApps: (state: AdminState) => state.selectedApps,
  activeCountryClusters: (state: AdminState) =>
    state.countryClusters.filter(
      (countryCluster) => countryCluster.is_active === true,
    ),
  countryClusters: (state: AdminState) => state.countryClusters,
  countryCluster: (state: AdminState) => (countryClusterId: number) => {
    const filteredClusters = state.countryClusters.filter(
      (countryCluster) => countryCluster.id === countryClusterId,
    );
    if (filteredClusters.length > 0) {
      return { ...filteredClusters[0] };
    }
  },
  myCountries: (state: AdminState) => state.myCountries,
  selectedCountries: (state: AdminState) => state.selectedCountries,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readUsers = read(getters.users);
export const readUserResult = read(getters.userResult);
export const readUserError = read(getters.userError);
export const readUserPasswordError = read(getters.userPasswordError);
export const readOneUser = read(getters.user);
export const readApps = read(getters.apps);
export const readAppResult = read(getters.appResult);
export const readOneApp = read(getters.app);
export const readSelectedUsers = read(getters.selectedUsers);
export const readSelectedApps = read(getters.selectedApps);
export const readActiveCountryClusters = read(getters.activeCountryClusters);
export const readCountryClusters = read(getters.countryClusters);
export const readOneCountryCluster = read(getters.countryCluster);
export const readMyCountries = read(getters.myCountries);
export const readSelectedCountries = read(getters.selectedCountries);
