import {
  IApp,
  ICountryCluster,
  ICountryLibrary,
  IUser,
  PasswordError,
  UserError,
} from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
  setUsers(state: AdminState, payload: IUser[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUser) {
    const users = state.users.filter((user: IUser) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
    state.userResult = payload.id;
  },
  clearUserResult(state: AdminState) {
    state.userResult = 0;
  },
  setUserError(state: AdminState, payload: UserError) {
    state.userError = payload;
  },
  clearUserError(state: AdminState) {
    state.userError = null;
  },
  setUserPasswordError(state: AdminState, payload: PasswordError) {
    state.userPasswordError = payload;
  },
  clearUserPasswordError(state: AdminState) {
    state.userPasswordError = null;
  },
  setApps(state: AdminState, payload: IApp[]) {
    state.apps = payload;
  },
  setApp(state: AdminState, payload: IApp) {
    const apps = state.apps.filter((app: IApp) => app.id !== payload.id);
    apps.push(payload);
    state.apps = apps;
    state.appResult = payload.id;
  },
  clearAppResult(state: AdminState) {
    state.appResult = 0;
  },
  setSelectedUsers(state: AdminState, payload: IUser[]) {
    state.selectedUsers = payload;
  },
  setSelectedApps(state: AdminState, payload: IApp[]) {
    state.selectedApps = payload;
  },
  setCountryClusters(state: AdminState, payload: ICountryCluster[]) {
    state.countryClusters = payload;
  },
  removeCountryCluster(state: AdminState, payload: ICountryCluster) {
    const countryClusters = state.countryClusters.filter(
      (cluster: ICountryCluster) => cluster.id !== payload.id,
    );
    state.countryClusters = countryClusters;
  },
  setMyCountries(state: AdminState, payload: ICountryLibrary) {
    state.myCountries = payload.countries;
  },
  setSelectedCountries(state: AdminState, payload: ICountryLibrary) {
    state.selectedCountries = payload.countries;
  },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUsers = commit(mutations.setUsers);
export const commitSetUser = commit(mutations.setUser);
export const commitClearUserResult = commit(mutations.clearUserResult);
export const commitSetUserError = commit(mutations.setUserError);
export const commitClearUserError = commit(mutations.clearUserError);
export const commitSetUserPasswordError = commit(
  mutations.setUserPasswordError,
);
export const commitClearUserPasswordError = commit(
  mutations.clearUserPasswordError,
);
export const commitSetApps = commit(mutations.setApps);
export const commitSetApp = commit(mutations.setApp);
export const commitClearAppResult = commit(mutations.clearAppResult);
export const commitSetSelectedUsers = commit(mutations.setSelectedUsers);
export const commitSetSelectedApps = commit(mutations.setSelectedApps);
export const commitSetCountryClusters = commit(mutations.setCountryClusters);
export const commitRemoveCountryCluster = commit(
  mutations.removeCountryCluster,
);
export const commitSetMyCountries = commit(mutations.setMyCountries);
export const commitSetSelectedCountries = commit(
  mutations.setSelectedCountries,
);
